.archive {
  .single-title {
    padding: 1rem 0 0.5rem;
    margin: 0;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 140%;
    text-align: right;
  }

  .single-subtitle {
    margin: 0.5rem 0;
    font-size: 1.2rem;
    font-weight: normal;
    line-height: 100%;
    text-align: right;
  }

  .group-title {
    font-size: 1.2rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  @import '../_partial/_archive/terms';
  @import '../_partial/_archive/tags';
}
